import React, {useEffect, useState} from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    List,
    ListItem,
    Box,
    Badge,
    Chip,
    Button,
    TextField,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import H2H from './H2H';
import Home from './Home';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';


function App() {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [view, setView] = useState('home');
    const [password, setPassword] = useState('');
    const [lastUpdate, setLastUpdate] = useState('');
    const [authenticated, setAuthenticated] = useState(false);

    const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuItemClick = (view) => {
    setView(view);
    setDrawerOpen(false);
  };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = () => {
        if (password === 'tennispocket4ever' || password === 'mürnic' || password === 'fredy1956' || password === 'well') {
            localStorage.setItem('authenticated', 'true');
            localStorage.setItem('authenticated1', password);
            setAuthenticated(true);
        } else {
            alert('Incorrect password');
        }
    };

    useEffect(() => {
        const auth = localStorage.getItem('authenticated');
        if (auth === 'true') {
            setAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        const authenticated1 = localStorage.getItem('authenticated1');

        fetch('https://betapi.tnx-solutions.ch/api/v1/auth/signin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ authenticated1 }),
        })
            .then(response => response.json())
            .then(data => {
                //console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    useEffect(() => {
            fetch(`https://betapi.tnx-solutions.ch/api/v1/player/last-update`)
                .then((response) => response.json())
                .then((lastUpdate) => {
                     const date = new Date(lastUpdate).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
                    setLastUpdate(`Updated: ${date}`);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
    }, []);

    return (
        <div className="App">
            {authenticated ? (
                    <>
                        <AppBar position="sticky">
                            <Toolbar>
                                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant="h6" sx={{ flexGrow: 1 }}><Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}> MyTennis Pocket</Link></Typography>
                                <Chip label={lastUpdate} variant="outlined" size="small" color="secondary" sx={{ color: 'white', borderColor: 'white' }}/>
                            </Toolbar>
                        </AppBar>
                        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
                            <List>
                                <ListItem
                                    button
                                    onClick={() => handleMenuItemClick('home')}
                                    sx={{
                                        padding: 2,
                                        backgroundColor: view === 'home' ? theme.palette.primary.main : 'transparent',
                                        color: view === 'home' ? theme.palette.primary.contrastText : theme.palette.text.primary,
                                    }}
                                >
                                    <Typography variant="body1">Home</Typography>
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => handleMenuItemClick('h2h')}
                                    sx={{
                                        padding: 2,
                                        backgroundColor: view === 'h2h' ? theme.palette.primary.main : 'transparent',
                                        color: view === 'h2h' ? theme.palette.primary.contrastText : theme.palette.text.primary,
                                    }}
                                >
                                    <Typography variant="body1">Head-to-Head</Typography>
                                </ListItem>
                            </List>
                        </Drawer>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100vh',
                            }}
                        >
                            {view === 'home' && <Home />}
                            {view === 'h2h' && <H2H />}
                        </Box>
                    </>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                    }}
                >
                    <Typography variant="h4" component="div" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                        MyTennis Pocket
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            p: 2,
                            borderRadius: '10px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            backgroundColor: 'background.paper',
                            border: '1px solid #e0e0e0',
                        }}
                    >
                        <TextField
                            variant="outlined"
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            label="Password"
                            sx={{ width: '300px' }}
                        />
                        <Button variant="contained" color="primary" onClick={handleLogin}>
                            Login
                        </Button>
                    </Box>
                </Box>
            )}
        </div>
    );
}

export default App;
