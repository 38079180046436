import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Tooltip, IconButton, Button, Link } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { useLocation } from 'react-router-dom';

function PlayerDetails({ selectedPlayer }) {
    const [playerData, setPlayerData] = useState(selectedPlayer); // Initialize playerData with selectedPlayer
    const [isFavorite, setIsFavorite] = useState(false);

    // Update playerData when selectedPlayer changes
    useEffect(() => {
        setPlayerData(selectedPlayer);
    }, [selectedPlayer]);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const player = params.get('player');

    useEffect(() => {
        const favoritePlayers = JSON.parse(localStorage.getItem('favoritePlayerIds')) || [];
        setIsFavorite(favoritePlayers.includes(selectedPlayer?.person?.id));
    }, [selectedPlayer]);

    const toggleFavorite = () => {
        const favoritePlayers = JSON.parse(localStorage.getItem('favoritePlayerIds')) || [];
        if (favoritePlayers.includes(playerData.person.id)) {
            const newFavorites = favoritePlayers.filter(id => id !== playerData.person.id);
            localStorage.setItem('favoritePlayerIds', JSON.stringify(newFavorites));
            setIsFavorite(false);
        } else {
            favoritePlayers.push(playerData.person.id);
            localStorage.setItem('favoritePlayerIds', JSON.stringify(favoritePlayers));
            setIsFavorite(true);
        }
    };

    // Classification arrays
    const men = [
        { classification: "N1", from: 1, to: 10 },
        { classification: "N2", from: 11, to: 30 },
        { classification: "N3", from: 31, to: 70 },
        { classification: "N4", from: 71, to: 150 },
        { classification: "R1", from: 151, to: 310 },
        { classification: "R2", from: 311, to: 630 },
        { classification: "R3", from: 631, to: 1270 },
        { classification: "R4", from: 1271, to: 2550 },
        { classification: "R5", from: 2551, to: 5110 },
        { classification: "R6", from: 5111, to: 10230 },
        { classification: "R7", from: 10231, to: 20470 },
        { classification: "R8", from: 20471, to: 30770 },
        { classification: "R9", from: 30771, to: Infinity }
    ];

    const women = [
        { classification: "N1", from: 1, to: 10 },
        { classification: "N2", from: 11, to: 24 },
        { classification: "N3", from: 25, to: 45 },
        { classification: "N4", from: 46, to: 75 },
        { classification: "R1", from: 76, to: 144 },
        { classification: "R2", from: 145, to: 284 },
        { classification: "R3", from: 285, to: 554 },
        { classification: "R4", from: 555, to: 1074 },
        { classification: "R5", from: 1075, to: 2074 },
        { classification: "R6", from: 2075, to: 4024 },
        { classification: "R7", from: 4025, to: 7824 },
        { classification: "R8", from: 7825, to: 11824 },
        { classification: "R9", from: 11825, to: Infinity }
    ];

    // Function to get live classification
    const getLiveClassification = (rank, gender) => {
        const classifications = gender === 'm' ? men : women;
        for (let classification of classifications) {
            if (rank >= classification.from && rank <= classification.to) {
                return {
                    classification: classification.classification,
                    range: `${classification.from}-${classification.to === Infinity ? 'restl.' : classification.to}`
                };
            }
        }
        return { classification: "n/a", range: "" }; // Default value if not found
    };

    const highestRanking = playerData?.rankingHistory?.sort((a, b) => a.rank - b.rank)[0];
    const rankingDifference = playerData?.ranking - playerData?.liveRanking?.newRank;
    const liveClassificationData = getLiveClassification(playerData?.liveRanking?.newRank, playerData?.gender);

    return (
        <Box
            sx={{
                p: 2,
                mt: 2,
                borderRadius: '10px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                backgroundColor: 'background.paper',
                width: { xs: '80%', sm: '600px' },
                border: '1px solid #e0e0e0',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="h6" component="div" gutterBottom>
                    {playerData?.person?.firstname} {playerData?.person?.lastname} {playerData?.licenceNumber}
                </Typography>

                <IconButton onClick={toggleFavorite} color="primary" sx={{ marginBottom: '8px' }}>
                    {isFavorite ? <StarIcon /> : <StarBorderIcon />}
                </IconButton>

                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: { xs: 'flex-end', sm: 'flex-start' } }}>
                    <Link href={`https://www.mytennis.ch/de/spieler/${playerData?.person?.id}`} target="_blank" rel="noopener noreferrer">
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<OpenInNewIcon />}
                            sx={{
                                color: 'grey.600',
                                borderColor: 'grey.400',
                                fontSize: '0.8rem',
                            }}
                        >
                            Swiss Tennis
                        </Button>
                    </Link>
                </Box>

            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h8" component="div" gutterBottom>
                        Alterskat.: {playerData?.ageCategoryRedundant}
                    </Typography>
                    <Typography variant="h8" component="div" gutterBottom>
                        Klassierung: {playerData?.classification}
                    </Typography>
                    <Typography variant="h8" component="div" gutterBottom>
                        Ranking: {playerData?.ranking}
                    </Typography>
                    <Typography variant="h8" component="div" gutterBottom>
                        Klassierungswert: {playerData?.classificationValue}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h8" component="div" gutterBottom>
                        Live Klassierungswert: ~{playerData?.liveRanking?.newClassificationValue}
                    </Typography>
                    <Typography variant="h8" component="div" gutterBottom>
                        Live Klassierung: <span style={{ fontWeight: 'bold' }}>~{liveClassificationData.classification} </span>({liveClassificationData.range})
                    </Typography>
                    <Typography variant="h8" component="div" gutterBottom>
                        Live Ranking: ~{playerData?.liveRanking?.newRank}
                        {rankingDifference > 0 && <span style={{ color: 'green' }}> (+{rankingDifference})</span>}
                        {rankingDifference < 0 && <span style={{ color: 'red' }}> ({rankingDifference})</span>}
                        <Tooltip title={new Date(playerData?.liveRanking?.createTs).toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day: '2-digit' })}>
                            <IconButton size="small" color="primary">
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                    <Typography variant="h8" component="div" gutterBottom>
                        Höchstes Ranking: <span style={{ fontWeight: 'bold' }}>{highestRanking?.classification} ({highestRanking?.rank}) </span>({new Date(highestRanking?.date).toLocaleDateString('en-GB', { month: '2-digit', year: 'numeric' })})
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default PlayerDetails;
